@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1400 - 320));
  height: 100vh;
}

body {
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
}

.Hero__background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0;
  height: 90vh;
  width: 100%;
}

.Hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  line-height: 1.5;
  letter-spacing: 2px;
}

.Hero__title {
  margin-top: 0;
  font-size: 3rem;
}

.Hero__subtitle {
  font-size: 2rem;
}

.Header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  max-width: 1400px;
}

.Header__title {
  margin-left: 1.9rem;
  color: black;
  font-size: 2rem;
  width: 3rem;
}

.Header__burger-container {
  position: absolute;
  top: 1vh;
  right: 0.75rem;
  height: 8vh;
  width: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header__burger {
  display: block;
  width: 28px;
  height: 3px;
  background: black;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
  cursor: pointer;
}

.Header__burger::before {
  display: block;
  content: '';
  width: 28px;
  height: 3px;
  background: black;
  margin: -8px 0 0 0;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
}

.Header__burger::after {
  display: block;
  content: '';
  width: 20px;
  height: 3px;
  background: black;
  margin: 13px 0 0 0;
  transition: opacity 500ms ease-in-out;
}

.Header__burger.show {
  transform: rotate(135deg);
}

.Header__burger.show::before {
  transform: rotate(90deg) translateX(8px);
  
}

.Header__burger.show::after {
  opacity: 0;
}

.Header__links {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  height: 100%;
  transition: height 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.Header__links ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.Header__links ul li {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0.8rem;
}

.Header__links ul li a {
  display: flex;
  align-items: center;
  font-family: 'Caveat';
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
}

.active {
  border-bottom: 3px solid rgb(0, 0, 0);
  margin-top: 3px;
}

.Header__links ul li:last-child {
  margin-right: 0;
}

.Header__logo {
  font-family: 'Caveat';
}

@media all and (min-width: 600px) {
  .Header__burger-container {
    display: none;
  }
}

@media all and (max-width: 599px) {
  .Header__container {
    flex-direction: column;
  }

  .Header__title {
    position: absolute;
    top: 1.5vh;
    width: 100%;
  }

  .Header__logo {
    height: 100%;
  }

  .Header__links {
    position: absolute;
    background-color: black;
    width: 100%;
    margin: 0;
    height: 0;
    overflow: hidden;
    flex-direction: column;
  }

  .Header__links.show {
    position: absolute;
    height: 90vh;
    top: 10vh;
    background: black;

  }

  .Header__links ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Header__links ul li {
    margin: 0;
    width: 100%;
  }

  .Header__links ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2rem;
    margin: 0;
    opacity: 0;
    transition: opacity 500ms ease-in-out 750ms;
  }

  .Header__links ul li a.show {
    opacity: 1;
  }
}


.AboutContentRow {
  display: flex;
  flex-direction: column;
  background-color: rgb(46, 46, 46);
  border-radius: 10px;
  color: white;
  text-align: center;
  padding: 0.8rem;
  margin: 1rem auto;
}

.row_image_container {
  width: 100%;
}

.row_image {
  display: block;
  width: 90%;
  max-width: 512px;
  margin: 0 auto;
  padding: 1.5rem 0;
  border-radius: 50%;
}

.row_text {
  margin: 0.8rem;
}

@media all and (min-width: 1000px) {
  .AboutContentRow {
    flex-direction: row;
    align-items: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .row_image_container {
    width: 50%;
  }

  .row_content {
    width: 50%;
    font-size: 0.9rem;
  }
}

.About {
  min-height: 100vh;
  background-color: black;
  padding: 10vh 2rem 2rem 2rem;
}

.About__header {
  color: white;
  text-align: center;
  font-size: 2rem;
  margin: 1rem 1rem 5vh 1rem;
}
.ProjectScreen {
  display: inline-block;
  position: absolute;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgb(46, 46, 46);
}

.ProjectScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 63%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 0 .5rem;
}

.ProjectScreen__icon {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  border: none;
  background: none;
}

.ProjectScreen__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  color: white;
  font-size: 0.75rem;
  padding: calc(95vh * 0.37) 1rem 1rem 1rem;
  text-align: center;
  line-height: 1.2;
}

.content__desc_container {
  padding-bottom: 0.75rem;
}

.content__links_container {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: .75rem;
}

.content__title {
  font-size: 1.3rem;
  margin-bottom: 0.75rem;
}

.content__next {
  background: none;
  border: none;
  color: white;
  padding: 15px 0 0 0;
  font-size: 1.3rem;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.content__links {
  color: white;
  font-weight: 600;
}

.content__stack p:last-child {
  padding-top: 0.5rem;
}

.content__stack_header {
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.content__stack_list_title {
  font-weight: 600;
}

@media all and (min-width: 667px) {
  .image_arrow {
    color: white;
  }

  .ProjectScreen__icon {
    padding: 0 1rem;
  }
}

@media all and (min-width: 100px) {
  .ProjectScreen__icon {
    padding: 0 4rem;
  }
}
.ProjectSlider {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.Projects {
  min-height: 100vh;
  background-color: white;
  padding: 10vh 0 2rem 0;
}

.Projects__header {
  color: black;
  text-align: center;
  font-size: 2rem;
  margin: 1rem 1rem 5vh 1rem;
}
.Contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 10vh 2rem 2rem 2rem;
  font-size: 1rem;
  text-align: center;
}

.Contact__header {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 1rem 5vh 1rem;
}

.Contact__info {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 75%;
  max-width: 300px;
  margin: 0 auto 0 auto;
}

.Contact__icon {
  color: white;
}

.Contact__link {
  display:flex;
  justify-content: space-between;
  text-decoration: none;
  width: 50%;
  color: white;
}

.link_text {
  display: inline-block;
}
header {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  height: 10vh;
  background-color: white;
  z-index: 10;
  box-shadow: 0px 0px 10px 3px rgba(75, 75, 75, 0.3);
}

section {
  max-width: 1400px;
  margin: 0 auto;
}

.headerWrapper {
  width: 100%;
  background-color: white;
}

.aboutWrapper {
  width: 100%;
  background-color: black;
}

.projectWrapper {
  width: 100%;
  background-color: white;
}

.contactWrapper {
  width: 100%;
  background-color: black;
}
.BlogHeader {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid black;
}

.BlogHeader__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  z-index: 2;
  background: white;
  font-size: 1.3rem;
}

.BlogHeader__burger {
  display: block;
  width: 28px;
  height: 3px;
  background: black;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
  cursor: pointer;
}

.BlogHeader__burger::before {
  display: block;
  content: '';
  width: 28px;
  height: 3px;
  background: black;
  margin: -8px 0 0 0;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
}

.BlogHeader__burger::after {
  display: block;
  content: '';
  width: 20px;
  height: 3px;
  background: black;
  margin: 13px 0 0 0;
  transition: opacity 500ms ease-in-out;
}

.BlogHeader__burger.show {
  transform: rotate(135deg);
}

.BlogHeader__burger.show::before {
  transform: rotate(90deg) translateX(8px);
  
}

.BlogHeader__burger.show::after {
  display: none;
}

.BlogHeader__nav {
  height: 0px;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  background: black;
  transition: height 500ms ease-in-out;
}

.BlogHeader__nav.show {
  height: 10vh;
}

.BlogHeader__nav-list {
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.BlogHeader__nav-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.BlogHeader__nav-link:hover {
  border-bottom: 2px solid white;
}

@media all and (min-width: 600px) {

  .BlogHeader {
    display: flex;
    justify-content: space-between;
  }

  .BlogHeader__burger,
  .BlogHeader__burger::before,
  .BlogHeader__burger::after {
    display: none;
  }

  .BlogHeader__nav {
    display: flex;
    height: 10vh;
    width: 40%;
    background: white;
  }

  .BlogHeader__nav-list {
    justify-content: space-between;
  }

  .BlogHeader__nav-link {
    color: black;
  }

  .BlogHeader__nav-link:hover {
    border-bottom: 2px solid black;
  }
}
.AllPosts {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  min-height: 100vh;
}

.AllPosts__posts-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.AllPosts__single-post {
  margin: 20px 0;
  max-height: 33vh;
  box-shadow: 0 0 10px 5px rgba(93, 93, 93, 0.5);
}

.AllPosts__post-link {
  text-decoration: none;
  color: black;
  height: 100%;
}

.AllPosts__post-image {
  display: block;
  width: 100%;
  height: 22vh;
  object-fit: cover;
}

.AllPosts__post-info {
  background: rgba(209, 209, 209, 0.7);
  height: 80%;
}

.AllPosts__post-details {
  display: block;
  border-left: 5px solid black;
  margin-top: -4px;
}

.AllPosts__post-title, 
.AllPosts__post-pub {
  padding: 0 .75rem;
}

.AllPosts__post-title {
  font-size: 1.2rem;
  padding-top: .5rem;
}

.AllPosts__post-pub {
  font-size: .8rem;
  padding-bottom: .5rem;
}
.BlogPost {
  padding: 1rem;
  line-height: 1.5;
  max-width: 1000px;
}

.BlogPost__info h2 {
  text-align: center;
  margin: 1rem 0 0 0;
}

.BlogPost__pub-info {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 0 0 .5rem 0;
  border-bottom: 1px dashed #bbb;
}

.BlogPost__pub-text {
  margin-left: .75rem;
}

.BlogPost .BlogPost__published {
  font-size: 0.75rem;
  margin: 0;
}

.BlogPost__author-image {
  border-radius: 50%;
}

.BlogPost__main-image {
  width: 100%;
}

.BlogPost div p {
  margin: 15px auto;
  font-size: 1rem;
}

.BlogPost div ul {
  padding-left: 40px;
}

.BlogPost div ul li {
  padding: 15px 0;
}

.BlogPost__main-content img {
  width: 100%;
} 



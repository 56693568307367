.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
}

.Hero__background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0;
  height: 90vh;
  width: 100%;
}

.Hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  line-height: 1.5;
  letter-spacing: 2px;
}

.Hero__title {
  margin-top: 0;
  font-size: 3rem;
}

.Hero__subtitle {
  font-size: 2rem;
}
header {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  height: 10vh;
  background-color: white;
  z-index: 10;
  box-shadow: 0px 0px 10px 3px rgba(75, 75, 75, 0.3);
}

section {
  max-width: 1400px;
  margin: 0 auto;
}

.headerWrapper {
  width: 100%;
  background-color: white;
}

.aboutWrapper {
  width: 100%;
  background-color: black;
}

.projectWrapper {
  width: 100%;
  background-color: white;
}

.contactWrapper {
  width: 100%;
  background-color: black;
}
.Projects {
  min-height: 100vh;
  background-color: white;
  padding: 10vh 0 2rem 0;
}

.Projects__header {
  color: black;
  text-align: center;
  font-size: 2rem;
  margin: 1rem 1rem 5vh 1rem;
}
.BlogHeader {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid black;
}

.BlogHeader__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  z-index: 2;
  background: white;
  font-size: 1.3rem;
}

.BlogHeader__burger {
  display: block;
  width: 28px;
  height: 3px;
  background: black;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
  cursor: pointer;
}

.BlogHeader__burger::before {
  display: block;
  content: '';
  width: 28px;
  height: 3px;
  background: black;
  margin: -8px 0 0 0;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
}

.BlogHeader__burger::after {
  display: block;
  content: '';
  width: 20px;
  height: 3px;
  background: black;
  margin: 13px 0 0 0;
  transition: opacity 500ms ease-in-out;
}

.BlogHeader__burger.show {
  transform: rotate(135deg);
}

.BlogHeader__burger.show::before {
  transform: rotate(90deg) translateX(8px);
  
}

.BlogHeader__burger.show::after {
  display: none;
}

.BlogHeader__nav {
  height: 0px;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  background: black;
  transition: height 500ms ease-in-out;
}

.BlogHeader__nav.show {
  height: 10vh;
}

.BlogHeader__nav-list {
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.BlogHeader__nav-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.BlogHeader__nav-link:hover {
  border-bottom: 2px solid white;
}

@media all and (min-width: 600px) {

  .BlogHeader {
    display: flex;
    justify-content: space-between;
  }

  .BlogHeader__burger,
  .BlogHeader__burger::before,
  .BlogHeader__burger::after {
    display: none;
  }

  .BlogHeader__nav {
    display: flex;
    height: 10vh;
    width: 40%;
    background: white;
  }

  .BlogHeader__nav-list {
    justify-content: space-between;
  }

  .BlogHeader__nav-link {
    color: black;
  }

  .BlogHeader__nav-link:hover {
    border-bottom: 2px solid black;
  }
}
.ProjectScreen {
  display: inline-block;
  position: absolute;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgb(46, 46, 46);
}

.ProjectScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 63%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 0 .5rem;
}

.ProjectScreen__icon {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  border: none;
  background: none;
}

.ProjectScreen__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  color: white;
  font-size: 0.75rem;
  padding: calc(95vh * 0.37) 1rem 1rem 1rem;
  text-align: center;
  line-height: 1.2;
}

.content__desc_container {
  padding-bottom: 0.75rem;
}

.content__links_container {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: .75rem;
}

.content__title {
  font-size: 1.3rem;
  margin-bottom: 0.75rem;
}

.content__next {
  background: none;
  border: none;
  color: white;
  padding: 15px 0 0 0;
  font-size: 1.3rem;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
}

.content__links {
  color: white;
  font-weight: 600;
}

.content__stack p:last-child {
  padding-top: 0.5rem;
}

.content__stack_header {
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.content__stack_list_title {
  font-weight: 600;
}

@media all and (min-width: 667px) {
  .image_arrow {
    color: white;
  }

  .ProjectScreen__icon {
    padding: 0 1rem;
  }
}

@media all and (min-width: 100px) {
  .ProjectScreen__icon {
    padding: 0 4rem;
  }
}
.AllPosts {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  min-height: 100vh;
}

.AllPosts__posts-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.AllPosts__single-post {
  margin: 20px 0;
  max-height: 33vh;
  box-shadow: 0 0 10px 5px rgba(93, 93, 93, 0.5);
}

.AllPosts__post-link {
  text-decoration: none;
  color: black;
  height: 100%;
}

.AllPosts__post-image {
  display: block;
  width: 100%;
  height: 22vh;
  object-fit: cover;
}

.AllPosts__post-info {
  background: rgba(209, 209, 209, 0.7);
  height: 80%;
}

.AllPosts__post-details {
  display: block;
  border-left: 5px solid black;
  margin-top: -4px;
}

.AllPosts__post-title, 
.AllPosts__post-pub {
  padding: 0 .75rem;
}

.AllPosts__post-title {
  font-size: 1.2rem;
  padding-top: .5rem;
}

.AllPosts__post-pub {
  font-size: .8rem;
  padding-bottom: .5rem;
}

.Header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  max-width: 1400px;
}

.Header__title {
  margin-left: 1.9rem;
  color: black;
  font-size: 2rem;
  width: 3rem;
}

.Header__burger-container {
  position: absolute;
  top: 1vh;
  right: 0.75rem;
  height: 8vh;
  width: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header__burger {
  display: block;
  width: 28px;
  height: 3px;
  background: black;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
  cursor: pointer;
}

.Header__burger::before {
  display: block;
  content: '';
  width: 28px;
  height: 3px;
  background: black;
  margin: -8px 0 0 0;
  transition: transform 1s cubic-bezier(0.39, 1, 0.78, 1.43);
}

.Header__burger::after {
  display: block;
  content: '';
  width: 20px;
  height: 3px;
  background: black;
  margin: 13px 0 0 0;
  transition: opacity 500ms ease-in-out;
}

.Header__burger.show {
  transform: rotate(135deg);
}

.Header__burger.show::before {
  transform: rotate(90deg) translateX(8px);
  
}

.Header__burger.show::after {
  opacity: 0;
}

.Header__links {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  height: 100%;
  transition: height 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.Header__links ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.Header__links ul li {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0.8rem;
}

.Header__links ul li a {
  display: flex;
  align-items: center;
  font-family: 'Caveat';
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
}

.active {
  border-bottom: 3px solid rgb(0, 0, 0);
  margin-top: 3px;
}

.Header__links ul li:last-child {
  margin-right: 0;
}

.Header__logo {
  font-family: 'Caveat';
}

@media all and (min-width: 600px) {
  .Header__burger-container {
    display: none;
  }
}

@media all and (max-width: 599px) {
  .Header__container {
    flex-direction: column;
  }

  .Header__title {
    position: absolute;
    top: 1.5vh;
    width: 100%;
  }

  .Header__logo {
    height: 100%;
  }

  .Header__links {
    position: absolute;
    background-color: black;
    width: 100%;
    margin: 0;
    height: 0;
    overflow: hidden;
    flex-direction: column;
  }

  .Header__links.show {
    position: absolute;
    height: 90vh;
    top: 10vh;
    background: black;

  }

  .Header__links ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Header__links ul li {
    margin: 0;
    width: 100%;
  }

  .Header__links ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2rem;
    margin: 0;
    opacity: 0;
    transition: opacity 500ms ease-in-out 750ms;
  }

  .Header__links ul li a.show {
    opacity: 1;
  }
}


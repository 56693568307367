.AboutContentRow {
  display: flex;
  flex-direction: column;
  background-color: rgb(46, 46, 46);
  border-radius: 10px;
  color: white;
  text-align: center;
  padding: 0.8rem;
  margin: 1rem auto;
}

.row_image_container {
  width: 100%;
}

.row_image {
  display: block;
  width: 90%;
  max-width: 512px;
  margin: 0 auto;
  padding: 1.5rem 0;
  border-radius: 50%;
}

.row_text {
  margin: 0.8rem;
}

@media all and (min-width: 1000px) {
  .AboutContentRow {
    flex-direction: row;
    align-items: center;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .row_image_container {
    width: 50%;
  }

  .row_content {
    width: 50%;
    font-size: 0.9rem;
  }
}
